<template>
  <div class="homes">
    <el-tabs type="border-card">
      <el-tab-pane label="优秀案例">
        <el-row :gutter="20">
          <el-col :span="23">
            <!-- 查询 -->
            <el-form
              :inline="true"
              class="demo-form-inline"
              style="margin-left: 6px; height: 53px"
            >
              <el-form-item label="案例名称：">
                <el-input
                  v-model="params.title"
                  placeholder="请输入案例名称"
                  class="formInput"
                ></el-input>
              </el-form-item>
              <!--          <el-form-item label="新闻标题：">-->
              <!--            <el-input-->
              <!--              v-model="pageInfo.wrapper.title"-->
              <!--              placeholder="请输入新闻标题"-->
              <!--            ></el-input>-->
              <!--          </el-form-item>-->
              <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="query()"
                  >查询</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button type="primary" icon="el-icon-refresh" @click="reset()"
                  >重置</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button type="primary" icon="el-icon-plus" @click="userAddData"
                  >新增</el-button
                >
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-row>
          <!-- 列表 -->
          <el-col :span="24"
            ><div class="grid-content bg-purple">
              <el-table
                :data="this.tableData"
                border
                max-height="580"
                style="width: 100%"
              >
                <el-table-column
                  type="index"
                  prop="sortOrder"
                  header-align="center"
                  align="center"
                  label="序号"
                  width="60"
                  :index="indexMethod"
                >
                </el-table-column>
                <el-table-column
                  prop="title"
                  label="案例名称"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="showTime"
                  header-align="center"
                  align="center"
                  label="显示时间"
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  prop="createDatetime"
                  header-align="center"
                  align="center"
                  label="创建时间"
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  label="操作"
                  header-align="center"
                  align="center"
                  width="200"
                >
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="primary"
                      icon="el-icon-edit"
                      @click="handleEdit(scope.row.id)"
                      >编辑</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      icon="el-icon-delete"
                      @click="handleDelete(scope.row.id)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div></el-col
          >
        </el-row>

        <!-- 分页 -->
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="size"
          layout="total, prev, pager, next, jumper"
          :total="recordsTotal"
          style="margin-top: 10px"
        >
        </el-pagination>
        <div style="margin-top: 10px; height: 3px; width: 100%"></div>
        <!-- 表单 -->
        <el-dialog
          :title="this.title"
          :visible.sync="dialogFormVisible"
          :append-to-body="true"
          top="5vh"
          width="800px"
          @close="close('formName')"
        >
          <el-form style="width: 100%" :model="form" :rules="rules" ref="formName">
            <el-form-item label="案例名称：" class="form" prop="title">
              <el-input
                v-model="form.title"
                placeholder="请输入案例名称"
                class="formInput"
              ></el-input>
            </el-form-item>
            <el-form-item label="显示时间：" class="form" prop="showTime">
              <el-date-picker
                v-model="form.showTime"
                type="datetime"
                placeholder="请输入显示时间"
                value-format="yyyy-MM-dd HH:mm"
                format="yyyy-MM-dd HH:mm"
                class="formInput"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="简短描述：" prop="shortDesc" class="form1">
              <el-input
                type="textarea"
                :rows="2"
                resize="none"
                v-model="form.shortDesc"
                placeholder="请输入简短描述"
                class="formInput"
                style="width: 79.4%"
              ></el-input>
            </el-form-item>
            <el-form-item label="案例内容：" class="form error" prop="content">
              <vue-editor
                v-model="form.content"
                style="width: 172.2%;
                  height:150px;
                  margin-left: 93px;
                  margin-top: 1px;"
                :editor-toolbar="customToolbar"
                class="formInput"
              ></vue-editor>
            </el-form-item>
            <el-form-item label="案例图片：" class="form1" prop="picPath">
              <el-upload
                action=""
                ref="upload"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                clearFiles
                class="formInput"
                style="margin-left: 92px; width: 79.8%;"
                :file-list="imgList"
                :limit="1"
                :before-upload="imgUpload"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
            <el-form-item
              style="display: flex;flex-direction: row;justify-content: flex-end;margin: 10px 0;"
            >
              <el-button type="primary" @click="formSave('formName')"
                >确 定</el-button
              >
              <el-button @click="formCancel('formName')">取 消</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane label="其他产品">
        <otherProductsAdmin />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { VueEditor } from 'vue2-editor'
import {
  newsQueryList,
  newsSave,
  newsUpdate,
  newsQueryById,
  newDelteById,
  queryTypeList,
  uploadFile,
  webServerHttpUrlString,
  newsNewsTypeList
} from '@/api/caseAdmin'
import { filePath } from '@/api/public'
import otherProductsAdmin from './otherProductsAdmin'
export default {
  name: 'caseAdmin',
  components: { VueEditor, otherProductsAdmin },
  data() {
    return {
      customToolbar: [
        // 富文本框配置文件
        ['bold', 'italic', 'color', 'background', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image', 'video', 'code-block', 'code', 'link'],
        ['clean']
      ],
      title: null, // 表单弹窗标题
      tableData: [], // 列表数据
      dialogFormVisible: false, // 表单弹框开关
      recordsTotal: null, // 数据总数
      // page: 1,
      pageSize: 5,
      // pageInfo: {
      //   // 分页查询数据
      //   page: 1,
      //   size: 10,
      //   params: {
      //     // 搜索数据
      //     title: null,
      //   }
      // },
      // pageInfo: {
      //   page: {
      //     current: 1,
      //     size: 10
      //   },
      // typeId: '',
      params: {
        title: null
      },
      current: 1,
      size: 10,
      // wrapper: {
      //   title: null
      // },
      // },
      folder: {
        folder: 'messageInfo'
      },
      form: {
        // 表单数据
        author: null,
        content: null,
        id: null,
        networkPath: null,
        networkProvenance: null,
        picPath: null,
        shortDesc: null,
        showTime: null,
        title: null,
        typeId: null
      },
      newsInfoTypeMap: [], // 新闻类型集合
      web: null, // 图片路径
      imgList: [], // 图片数据
      dialogImageUrl: '', // 图片显示的路径
      dialogVisible: false, // 查看文件的弹窗开关
      rules: {
        // 表单校验
        typeId: [{ required: true, message: '请选择类型', trigger: 'blur ' }],
        author: [{ required: true, message: '请输入作者', trigger: 'blur' }],
        title: [{ required: true, message: '请输入新闻标题', trigger: 'blur' }],
        networkProvenance: [
          { required: true, message: '请输入网络出处', trigger: 'blur' }
        ],
        networkPath: [
          { required: true, message: '请输入出处路径', trigger: 'blur' }
        ],
        shortDesc: [
          { required: true, message: '请输入简短描述', trigger: 'blur' }
        ],
        showTime: [
          { required: true, message: '请输入显示时间', trigger: 'blur' }
        ],
        picPath: [
          { required: true, message: '请选择新闻图片', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入新闻内容', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getList()
    webServerHttpUrlString().then(res => {
      this.web = res
    })
    newsNewsTypeList().then(res => {
      this.newsInfoTypeMap = res
    })
  },
  mounted() {},
  methods: {
    // 文件上传时钩子
    imgUpload(file) {
      this.imgList = []
      console.log(file)
      let formdata = new FormData()
      formdata.append('folder', 'industryInformation')
      formdata.append('file', file)
      uploadFile(formdata).then(res => {
        console.log('res', res)
        // this.dialogImageUrl = this.web + res;
        this.imgList.push({
          url: this.web + res
        })
        console.log(this.imgList)
        this.form.picPath = res
      })
    },
    // 文件列表移除文件时的钩子
    handleRemove() {
      this.form.picPath = null
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 图片上传返回的数据
    // handleAvatarSuccess(response) {
    //   this.form.picPath = response;
    // },
    // 弹框关闭事件
    close(formName) {
      this.$refs[formName].resetFields()
      this.$refs.upload.clearFiles()
    },
    // 序号
    indexMethod(index) {
      index = index + 1 + (this.current - 1) * this.size
      return index
    },
    //清空表单
    formData() {
      this.form.author = null
      this.form.content = null
      this.form.networkPath = null
      this.form.networkProvenance = null
      this.form.picPath = null
      this.form.shortDesc = null
      this.form.showTime = null
      this.form.title = null
      this.form.typeId = null
      this.form.id = null
    },
    //格式化类型
    // codeName(row) {
    //   if (this.tableData != null) {
    //     for (let i = 0; i < this.newsInfoTypeMap.length; i++) {
    //       if (row.typeId === this.newsInfoTypeMap[i].id) {
    //         return this.newsInfoTypeMap[i].name;
    //       }
    //     }
    //   }
    // },
    // 获取列表内容
    getList() {
      newsQueryList(this.current, this.size, this.params).then(res => {
        this.recordsTotal = res.total
        this.tableData = res.records
      })
    },
    // 查询
    query() {
      this.getList()
    },
    // 重置
    reset() {
      this.params.typeId = null
      this.getList()
    },
    //保存按钮
    formSave(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm('您确定保存吗？', '信息提示', {
            cancelButtonClass: 'btn-custom-cancel',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              console.log(this.form)
              if (this.form.id == null) {
                newsSave(this.form).then(res => {
                  console.log(res)
                  this.$alert('保存成功！', '信息提示', {
                    confirmButtonText: '确定',
                    type: 'success'
                  }).then(() => {
                    this.getList()
                    this.formData()
                    this.dialogFormVisible = false
                    this.$refs[formName].resetFields()
                  })
                })
              } else {
                newsUpdate(this.form).then(res => {
                  console.log(res)
                  this.$alert('保存成功！', '信息提示', {
                    confirmButtonText: '确定',
                    type: 'success'
                  }).then(() => {
                    this.getList()
                    this.formData()
                    this.dialogFormVisible = false
                    this.$refs[formName].resetFields()
                  })
                })
              }
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    //新增
    userAddData() {
      this.title = '新增'
      this.formData()
      this.dialogFormVisible = true
    },
    //编辑
    async handleEdit(id) {
      this.imgList = []
      this.title = '编辑'
      await newsQueryById(id).then(res => {
        this.form = res
        this.form.id = res.id
        filePath().then(httpsPath => {
          this.imgList.push({
            url: httpsPath + res.picPath
          })
        })
      })
      this.$nextTick(() => {
        this.dialogFormVisible = true
      })
    },
    //删除
    handleDelete(id) {
      this.$confirm('您确定删除吗？', '信息提示', {
        cancelButtonClass: 'btn-custom-cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          newDelteById(id).then(res => {
            console.log(res)
            this.$alert('删除成功！', '信息提示', {
              confirmButtonText: '确定',
              type: 'success'
            }).then(() => {
              this.getList()
            })
          })
        })
        .catch(() => {})
    },
    // 取消
    formCancel(formName) {
      this.dialogFormVisible = false
      this.$refs[formName].resetFields()
    },
    //  分页
    handleCurrentChange(val) {
      this.current = val
      this.getList()
    }
  }
}
</script>
<style lang="less" scoped>
.homes {
  margin: auto;
}
.form {
  width: 46%;
  display: inline-block;
  margin: 10px 10px;
}
.form1 {
  width: 100%;
  display: inline-block;
  margin: 10px 10px;
}
.formInput {
  width: 67%;
}
/deep/.el-dialog__body {
  margin-top: -19px;
  margin-left: 15px;
}
/deep/.ql-editor {
  min-height: 100px !important;
}

/deep/.el-upload--picture-card {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  line-height: 80px;
  vertical-align: top;
}
/deep/.el-upload--picture-card {
  width: 70px;
  height: 70px;
  margin-top: 40px;
}

/deep/.ql-container {
  height: 50%;
}
/deep/.el-dialog__body {
  margin-left: 15px;
}
/deep/.el-form-item__error {
  margin-left: 95px;
}
.error/deep/.el-form-item__error {
  margin-top: 0;
}
/deep/ #quill-container {
  height: 100px;
}
/deep/ [data-v-0dd82d4c] .ql-editor {
  min-height: 100px !important;
}
/deep/.quillWrapper .ql-snow.ql-toolbar {
  padding-bottom: 0px;
}
/*去除upload组件过渡效果*/
/deep/ .el-upload-list__item {
  transition: none !important;
}
</style>
